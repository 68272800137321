define([
    'lodash',
    'coreUtils',
    'santa-core-utils',
    'componentsCore',
    'santa-components',
    'components/components/bootstrap/menuButton/menuButton',
    'reactDOM',
    'zepto',
    'components/common/translationRequirementsChecker',
    'comboBoxInput'
], function (
    _,
    coreUtils,
    coreUtilsLib,
    componentsCore,
    santaComponents,
    menuButton,
    reactDOM,
    $,
    translationRequirementsChecker,
    comboBoxInput
) {
    'use strict';

    const OPEN_SUBMENUS_PREVIEW_STATE = 'open';

    function isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    function convertToNumber(n) {
        return isNumber(n) ? parseInt(n, 10) : undefined;
    }

    function shouldPreviewSubMenu(previewState) {
        return _.includes(previewState, OPEN_SUBMENUS_PREVIEW_STATE);
    }

    function getButtonPositionInList(menuPages, dropDown, stretch, buttonAlign, rtl, index) { // eslint-disable-line complexity
        if (index === menuPages.length - 1) {
            if (menuPages.length === 1) {
                return 'dropLonely';
            }
            if (dropDown) {
                return 'bottom';
            }
            if (!stretch && buttonAlign !== 'right') {
                return 'center';
            }
            return rtl ? 'left' : 'right';
        }
        if (index === 0) {
            if (dropDown) {
                return 'top';
            }
            if (!stretch && buttonAlign !== 'left') {
                return 'center';
            }
            return rtl ? 'right' : 'left';
        }
        return dropDown ? 'dropCenter' : 'center';
    }

    function getMenuItemIsSelectedPredicat(currentUrlPageId, openedPopupId, menuPages, highlightAnchorsInMenu, anchorChangeData) {
        const hasLinkToOpenedPopup = _.some(menuPages, ['link.pageId.id', openedPopupId]);
        let activeAnchor;
        const isSelectedMap = {
            AnchorLink: menuItem => {
                const anchorDataId = menuItem.link.anchorDataId;
                return (_.has(anchorDataId, 'id') ? anchorDataId.id : anchorDataId) === activeAnchor;
            },
            PageLink: menuItem => {
                const pageLink = menuItem.link.pageId;
                if (!activeAnchor) {
                    if (pageLink.isPopup) {
                        return pageLink.id === openedPopupId;
                    } else if (!hasLinkToOpenedPopup) {
                        return pageLink.id === currentUrlPageId;
                    }
                }

                return false;
            },
            DynamicPageLink: menuItem => menuItem.isSelected
        };

        if (highlightAnchorsInMenu) {
            activeAnchor = coreUtils.menuUtils.getActiveAnchorInPage(anchorChangeData, menuPages, currentUrlPageId);
        }

        return function (menuItem) {
            const link = menuItem.link;
            const isSelectedPredicate = link && isSelectedMap[link.type];

            return isSelectedPredicate ? isSelectedPredicate(menuItem) : false;
        };
    }

    function getMenuBtnState(anchorChangeData, menuPages, dropDown, stretch, buttonAlign, currentUrlPageId, currentPopupId, rtl, highlightAnchorsInMenu) {
        return _.map(menuPages, function (item, index) {
            const isSelected = getMenuItemIsSelectedPredicat(currentUrlPageId, currentPopupId, menuPages, highlightAnchorsInMenu, anchorChangeData);

            return {
                isContainer: Boolean(dropDown),
                isSelected: isSelected(item),
                positionInList: getButtonPositionInList(menuPages, dropDown, stretch, buttonAlign, rtl, index)
            };
        });
    }

    function flattenMenuItemsWithParentId(menuItems) {
        return _.flatMap(menuItems, function (item) {
            let menuChildren = [];
            if (item.items) {
                menuChildren = _.map(item.items, function (subItem) {
                    return _.assign(subItem, {
                        parent: item.id
                    });
                });
            }
            return [item].concat(menuChildren);
        });
    }

    const shouldRenderWixDropdown = ({isResponsive, isExperimentOpen, renderType}) => isResponsive || (isExperimentOpen('bv_wixDropdown') && renderType === 'bolt');

    function focusElement(ref) {
        const domNode = reactDOM.findDOMNode(ref);
        const tabbableElements = componentsCore.utils.accessibility.getTabbaleElements(domNode, true);
        $(_.head(tabbableElements)).focus();
    }

    /**
     * @class components.DropDownMenu
     * @extends {core.skinBasedComp}
     * @extends {coreUtilsLib.timersMixins.timeoutsMixin}
     * @extends {componentsCore.skinInfo}
     */
    const dropDownMenu = {
        displayName: 'DropDownMenu',
        propTypes: _.assign({
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
            anchorChangeEvent: santaComponents.santaTypesDefinitions.SiteAspects.anchorChangeEvent,
            currentUrlPageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId,
            currentPopupId: santaComponents.santaTypesDefinitions.currentPopupId,
            highlightAnchorsInMenu: santaComponents.santaTypesDefinitions.BrowserFlags.highlightAnchorsInMenu.isRequired,
            menuItems: santaComponents.santaTypesDefinitions.Menu.menuItems,
            id: santaComponents.santaTypesDefinitions.Component.id,
            refInParent: santaComponents.santaTypesDefinitions.Component.refInParent,
            skin: santaComponents.santaTypesDefinitions.Component.skin,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            componentPreviewState: santaComponents.santaTypesDefinitions.RenderFlags.componentPreviewState,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen.isRequired,
            renderType: santaComponents.santaTypesDefinitions.PublicModel.renderType,
            isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
            getTranslatedAriaLabel: santaComponents.santaTypesDefinitions.Accessibility.getTranslatedAriaLabel.isRequired,
            scale: santaComponents.santaTypesDefinitions.Component.scale
        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(menuButton), santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(comboBoxInput)),
        mixins: [
            componentsCore.mixins.skinBasedComp,
            coreUtilsLib.timersMixins.timeoutsMixin,
            componentsCore.mixins.skinInfo,
            componentsCore.mixins.createChildComponentMixin,
            componentsCore.mixins.mobileDropDownMenuMixin
        ],
        getInitialState() {
            this.shouldChildrenUpdate = false;

            return {
                hover: null,
                hoverListPosition: null,
                activeAnchor: null,
                $dropAlign: this.props.compProp.alignButtons,
                $mobile: this.props.isMobileDevice || this.props.isMobileView || this.props.isTabletDevice() ? 'mobile' : 'notMobile'
            };
        },
        componentDidMount() {
            if (this.props.highlightAnchorsInMenu()) {
                this.props.anchorChangeEvent.registerToAnchorChange(this);
            }
        },
        componentDidUpdate() {
            if (shouldRenderWixDropdown(this.props)) {
                this.refs[''].reLayout();
            }
        },
        componentWillUnmount() {
            if (this.props.highlightAnchorsInMenu()) {
                this.props.anchorChangeEvent.unregisterToAnchorChange(this);
            }
        },
        convertItemsToChildren(items, childBase, overrideLineHeight, dropDown) {
            childBase = childBase || {};
            childBase.style = childBase.style || {}; // eslint-disable-line santa/no-side-effects

            // TODO when MOBX will work - use this.props.activeAnchorData instead of this.state.activeAnchor
            const cssStates = getMenuBtnState(this.state.activeAnchor, items, dropDown, this.props.compProp.stretchButtonsToMenuWidth, this.props.compProp.alignButtons, this.props.currentUrlPageId, this.props.currentPopupId, this.props.compProp.rtl, this.props.highlightAnchorsInMenu());
            return _.map(items, function (item, index) {
                const ref = (dropDown ? 'moreContainer' : '') + index;
                return this.createChildComponent(item, 'core.components.MenuButton',
                    'repeaterButton',
                    _.merge({
                        isContainer: cssStates[index].isContainer,
                        isSelected: cssStates[index].isSelected,
                        positionInList: cssStates[index].positionInList,
                        id: this.props.id + ref,
                        ref,
                        key: item.id,
                        refInParent: ref,
                        mouseEnterHandler: this.mouseEnterHandler,
                        mouseLeaveHandler: this.mouseLeaveHandler,
                        isDropDownButton: dropDown,
                        onMouseClick: this.onMouseClick,
                        'aria-haspopup': item.items.length > 0,
                        tagName: 'li',
                        direction: this.props.compProp.rtl ? 'rtl' : 'ltr',
                        parentId: item.parent,
                        dataId: item.id
                    }, childBase));
            }.bind(this));
        },
        onMouseClick(event, ref, isSubMenuButton) { // eslint-disable-line complexity
            if (!isSubMenuButton) {
                const subItems = ref !== '__more__' ? this.props.menuItems[ref].items : [];
                const hasChildren = subItems.length > 0 || ref === '__more__';
                const currentDropdownOwnerRef = this.state.hover;
                if (!this.dropDownOpen && hasChildren) {
                    this.mouseEnterHandler(ref);
                    event.preventDefault();
                    event.stopPropagation();
                } else if (this.dropDownOpen && !this.isDropdownOwner(ref, currentDropdownOwnerRef) && hasChildren) {
                    this.mouseLeaveHandler();
                    event.preventDefault();
                    event.stopPropagation();
                    this.mouseEnterHandler(ref);
                } else if (this.dropDownOpen) {
                    this.mouseLeaveHandler();
                }
            } else {
                this.mouseLeaveHandler();
            }
        },

        onAnchorChange(newActiveAnchor) {
            if (newActiveAnchor !== this.state.activeAnchor) {
                this.setState({activeAnchor: newActiveAnchor});
            }
        },

        isDropdownOwner(currentClickRef, openDropdownOwnerRef) {
            return currentClickRef === openDropdownOwnerRef;
        },
        createMoreButton(rtl) {
            const itemId = '__more__';
            let positionInList = rtl ? 'left' : 'right';
            const buttonAlign = this.props.compProp.alignButtons;
            const stretch = this.props.compProp.stretchButtonsToMenuWidth;
            if (!stretch && buttonAlign !== 'right') {
                positionInList = 'center';
            }
            const moreButton = this.createChildComponent({id: itemId, label: this.props.compProp.moreButtonLabel},
                'core.components.MenuButton',
                'repeaterButton',
                {
                    isSelected: false,
                    positionInList,
                    id: this.props.id + itemId,
                    ref: itemId,
                    key: itemId,
                    refInParent: itemId,
                    mouseEnterHandler: this.mouseEnterHandler,
                    mouseLeaveHandler: this.mouseLeaveHandler,
                    onFocus: this.mouseEnterHandler,
                    onBlur: this.mouseLeaveHandler,
                    'aria-haspopup': true,
                    tagName: 'li',
                    onKeyDown: this.subMenuKeyDownHandler,
                    onMouseClick: this.onMouseClick,
                    isDropDownButton: false,
                    display: 'inline-block'
                });
            return moreButton;
        },
        mouseEnterHandler(childId, hoverListPos) {
            this.hovering = true;
            this.lastHovered = this.getCurrentTime();
            const newHoverChildId = childId.replace(this.props.id, '');
            const menuItemsIds = _.keys(this.props.menuItems).concat('__more__');
            const childIndex = menuItemsIds.indexOf(newHoverChildId);
            if (childIndex !== -1 && (isNumber(newHoverChildId) || coreUtils.stringUtils.startsWith(childId, '__')) && childId !== this.state.hover) {
                if (this.state.hover) {
                    this.refs[this.state.hover].setIdleState();
                }
                if (!shouldRenderWixDropdown(this.props)) {
                    this.registerReLayout(); //needed in order to calculate if the hover is opened up/down
                }
                this.setState({hover: childId, hoverListPosition: hoverListPos});
            }
        },
        getCurrentTime() {
            return Date.now();
        },
        mouseLeaveHandler() {
            this.hovering = false;
            this.lastHovered = this.getCurrentTime();
            if (!this.dropDownOpen && this.state.hover) {
                this.refs[this.state.hover].setIdleState();
            }
            this.setTimeout(function () {
                const timeSinceLastHovered = this.getCurrentTime() - this.lastHovered;
                if (!this.hovering && this.state.hover && timeSinceLastHovered >= 1000) {
                    this.refs[this.state.hover].setIdleState();
                    this.dropDownOpen = false;
                    this.setState({hover: null, hoverListPosition: null});
                }
            }.bind(this), 1000);
        },
        getParamsFromSkins() {
            return {
                menuBorderY: this.getSumParamValue('menuTotalBordersY', this.props.skin),
                menuBtnBorder: this.getSumParamValue('menuButtonBorders', this.getSkinExports().repeaterButton.skin),
                ribbonEls: this.getParamFromDefaultSkin('ribbonEls').value ? parseInt(this.getParamFromDefaultSkin('ribbonEls').value, 10) : 0,
                labelPad: this.getFromExports('labelPad'),
                ribbonExtra: this.getFromExports('ribbonExtra') ? Math.abs(parseInt(this.getFromExports('ribbonExtra'), 10)) : 0
            };
        },

        shiftFocusToMenu(indexOfTabToFocus) {
            const ref = this.refs[`${indexOfTabToFocus}`];
            this.dropDownOpen = false;
            focusElement(ref);
            this.currentFocusedSubMenuIndex = -1;
        },

        shiftFocusToSubMenu(indexOfTabToFocus) {
            const ref = this.refs[`moreContainer${indexOfTabToFocus}`];
            focusElement(ref);
            this.currentFocusedSubMenuIndex = indexOfTabToFocus;
        },

        getFirstIndexOfSubMenu() {
            let buttonDOMNode;
            for (let i = 0; i < this.subMenuItems.length; i++) {
                buttonDOMNode = reactDOM.findDOMNode(this.refs[`moreContainer${i}`]);
                if (window.getComputedStyle(buttonDOMNode).display !== 'none') {
                    return i;
                }
            }
            return 0;
        },

        mainMenuKeyDownHandler(evt) {
            if (evt.key !== 'Tab' || !this.dropDownOpen) {
                return;
            }

            // on press Shift + Tab with opened submenu we should move focus to the previous menu item,
            // close dropdown menu and clear focused sub menu item index
            if (evt.shiftKey) {
                const currentFocusedMenuIndex = convertToNumber(this.state.hover);
                if (currentFocusedMenuIndex !== undefined && currentFocusedMenuIndex - 1 >= 0) {
                    evt.stopPropagation();
                    evt.preventDefault();
                    this.shiftFocusToMenu(currentFocusedMenuIndex - 1);
                }
            } else {
                evt.stopPropagation();
                evt.preventDefault();
                const tabIndexToFocus = this.getFirstIndexOfSubMenu();
                this.shiftFocusToSubMenu(tabIndexToFocus);
            }
        },

        subMenuKeyDownHandler(evt) { // eslint-disable-line complexity
            let newIndexToFocus;
            if (!evt.shiftKey && evt.key === 'Tab') { // eslint-disable-line no-mixed-operators
                newIndexToFocus = this.currentFocusedSubMenuIndex + 1;
                if (newIndexToFocus === this.subMenuItems.length) { // last sub-menu item
                    const currentFocusedMenuIndex = convertToNumber(this.state.hover);
                    // focus next menu item if exist. If current menu item is the last one, let the browser take care of focusing next focusable item
                    if (currentFocusedMenuIndex !== undefined && currentFocusedMenuIndex + 1 < this.props.menuItems.length) {
                        evt.stopPropagation();
                        evt.preventDefault();
                        this.shiftFocusToMenu(currentFocusedMenuIndex + 1);
                    }
                } else {
                    evt.stopPropagation();
                    evt.preventDefault();
                    this.shiftFocusToSubMenu(newIndexToFocus);
                }
            }

            if (evt.shiftKey && evt.key === 'Tab') { // eslint-disable-line no-mixed-operators
                evt.stopPropagation();
                evt.preventDefault();
                newIndexToFocus = this.currentFocusedSubMenuIndex - 1;
                if (newIndexToFocus < 0 || newIndexToFocus < this.getFirstIndexOfSubMenu()) { // first sub-menu item
                    let currentFocusedMenu = convertToNumber(this.state.hover);
                    if (currentFocusedMenu === undefined) {
                        currentFocusedMenu = '__more__';
                    }
                    this.shiftFocusToMenu(currentFocusedMenu);
                } else {
                    this.shiftFocusToSubMenu(newIndexToFocus);
                }
            }
        },

        createHoverChildren(childrenToConvert) {
            const hoverChildren = this.convertItemsToChildren(childrenToConvert, {
                style: {width: '100%'},
                display: 'block',
                prefix: '_',
                subMenu: true
            }, true, true);

            if (hoverChildren.length > 0) {
                this.dropDownOpen = true;
                this.subMenuItems = hoverChildren;
            }

            return hoverChildren;
        },

        checkMoreVisibilityInPreviewState(moreVisibility, hoverChildren) {
            let firstParentIndex;
            if (this.props.isExperimentOpen('previewSubMenu') && shouldPreviewSubMenu(this.props.componentPreviewState)) {
                moreVisibility = 'inherit';
                const hasChildren = menuItem => menuItem.items.length > 0;
                firstParentIndex = _.findIndex(this.props.menuItems, hasChildren);
                const firsParentPage = _.find(this.props.menuItems, hasChildren);
                const moreItems = this.state.hover === '__more__' ? flattenMenuItemsWithParentId(this.props.menuItems) : [];
                const previewSubMenuItems = firsParentPage ? firsParentPage.items : moreItems;

                if (previewSubMenuItems.length) {
                    hoverChildren = this.createHoverChildren(previewSubMenuItems);
                }

                if (hoverChildren.length > 0) {
                    moreVisibility = 'inherit';
                }
            }

            return {firstParentIndex, hoverChildrenOverride: hoverChildren, moreVisibilityOverride: moreVisibility};
        },

        applyPreviewStateIfNeeded(moreVisibility, hoverChildren) {
            const {firstParentIndex, hoverChildrenOverride, moreVisibilityOverride} = this.checkMoreVisibilityInPreviewState(moreVisibility, hoverChildren);

            const hoverPositionOverride = _.isUndefined(firstParentIndex) ? '' : firstParentIndex;
            let moreContainerHoverIndex;

            if (_.isString(this.state.hover) && !_.isEmpty(this.state.hover)) {
                moreContainerHoverIndex = this.state.hover;
            } else {
                moreContainerHoverIndex = _.isNumber(hoverPositionOverride) ? hoverPositionOverride : null;
            }

            const dropPosition = this.state.hover ? this.state.hoverListPosition : hoverPositionOverride;

            return {dropPosition, moreContainerHoverIndex, hoverChildrenOverride, moreVisibilityOverride};
        },

        getAriaLabel() {
            const MENU_ARIA_LABEL_KEY = 'dropDownMenu_AriaLabel_TopLevel_SiteNavigation';
            return this.props.getTranslatedAriaLabel('AriaLabels', MENU_ARIA_LABEL_KEY, 'Site');
        },

        /* eslint-disable complexity */
        getSkinProperties() {
            if (this.props.isMobileView) {
                const flatMenuItems = this.flattenMenuItems(this.props.menuItems);
                const comboBoxItems = this.convertMenuItemsToComboBoxItems.call(this, flatMenuItems, this.props.compProp.alignText);
                return {
                    '': {
                        tagName: 'nav',
                        'aria-label': this.getAriaLabel()
                    },
                    navContainer: this.createChildComponent(
                        {options: comboBoxItems, value: this.getSelectedPage(comboBoxItems), id: 'navContainer'},
                        'wysiwyg.viewer.components.inputs.ComboBoxInput',
                        'navContainer',
                        {
                            onSelectionChange: this.onSelectionChange,
                            scale: this.props.scale,
                            compProp: {
                                class: 'mobileMenuContainer',
                                collectionClass: 'mobileCollection',
                                textAlignment: this.props.compProp.alignText,
                                textPadding: 20,
                                placeholder: {
                                    text: 'Choose a page',
                                    value: 'Choose a page'
                                }
                            },
                            setRuntimeCompData: _.noop,
                            setRuntimeCompProps: _.noop
                        }
                    )
                };
            }

            const paramsFromSkins = this.getParamsFromSkins();
            const children = this.convertItemsToChildren(this.props.menuItems, {display: 'inherit'}, null, null);
            let hoverChildren = [];
            let moreVisibility = 'hidden';
            children.push(this.createMoreButton(this.props.compProp.rtl));

            if ((isNumber(this.state.hover) && this.props.menuItems[this.state.hover]) || this.state.hover === '__more__') { // eslint-disable-line
                hoverChildren = this.createHoverChildren(isNumber(this.state.hover) ? this.props.menuItems[this.state.hover].items : flattenMenuItemsWithParentId(this.props.menuItems));
                if (hoverChildren.length > 0) {
                    moreVisibility = 'inherit';
                }
            }

            const {dropPosition, moreVisibilityOverride, moreContainerHoverIndex, hoverChildrenOverride} = this.applyPreviewStateIfNeeded(moreVisibility, hoverChildren);

            return {
                '': {
                    id: this.props.id,
                    key: this.props.refInParent,
                    className: 'hidden-during-prewarmup',
                    style: {
                        'overflowX': 'hidden'
                    },
                    'data-stretch-buttons-to-menu-width': !!this.props.compProp.stretchButtonsToMenuWidth,
                    'data-same-width-buttons': !!this.props.compProp.sameWidthButtons,
                    'data-num-items': _.size(this.props.menuItems),
                    'data-menuborder-y': paramsFromSkins.menuBorderY,
                    'data-menubtn-border': paramsFromSkins.menuBtnBorder,
                    'data-ribbon-els': paramsFromSkins.ribbonEls,
                    'data-label-pad': paramsFromSkins.labelPad,
                    'data-ribbon-extra': paramsFromSkins.ribbonExtra,
                    'data-drophposition': dropPosition,
                    'data-dropalign': this.props.compProp.alignButtons,
                    dir: this.props.compProp.rtl ? 'rtl' : 'ltr',
                    tagName: shouldRenderWixDropdown(this.props) ? 'wix-dropdown-menu' : 'div'
                },
                'navContainer': {
                    'aria-label': this.getAriaLabel()
                },
                'itemsContainer': {
                    children,
                    style: {
                        textAlign: this.props.compProp.alignButtons
                    },
                    onKeyDown: this.mainMenuKeyDownHandler
                },
                'moreContainer': {
                    onKeyDown: this.subMenuKeyDownHandler,
                    children: hoverChildrenOverride,
                    'data-hover': moreContainerHoverIndex,
                    style: {visibility: moreVisibilityOverride},
                    id: `${this.props.id}moreContainer`
                },
                'dropWrapper': {
                    style: {visibility: moreVisibilityOverride},
                    'data-drophposition': dropPosition,
                    'data-dropalign': this.props.compProp.alignButtons
                }
            };
        }
        /* eslint-enable complexity */
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.menus.DropDownMenu', dropDownMenu);
    translationRequirementsChecker.registerCommonLanguageRequirement(
        'wysiwyg.viewer.components.menus.DropDownMenu',
        (siteData, compInfo) => _.get(compInfo, ['properties', 'dataLang']));

    return dropDownMenu;
});
