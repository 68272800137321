define([
    'lodash',
    'coreUtils'
], function (
    _,
    coreUtils
) {
    'use strict';

    const SELF_UPDATE = _.keyBy([
        coreUtils.siteConstants.ACTION_TYPES.SYNC_VALIDATION_DATA,
        coreUtils.siteConstants.ACTION_TYPES.SET_CUSTOM_VALIDITY,
        coreUtils.siteConstants.ACTION_TYPES.UPDATE_VALIDITY_INDICATION,
        coreUtils.mediaConsts.ACTION_TYPES.MUTE,
        coreUtils.mediaConsts.ACTION_TYPES.UN_MUTE
    ]);

    function handle(behaviors, siteAPI, event) {
        const behaviorsAspect = siteAPI.getSiteAspect('behaviorsAspect');
        _.forEach(behaviors, function (behavior) {
            behaviorsAspect.registerBehavior(behavior, event.callback);
        });

        const needUpdate = _.some(behaviors, function (behavior) {
            return !_.has(SELF_UPDATE, behavior.name);
        });

        if (needUpdate) {
            siteAPI.forceUpdate();
        }
    }

    return {
        handle
    };
});
