define([
    'lodash',
    'santa-components',
    'prop-types',
    'componentsCore',
    'reactDOM'
], function (
    _,
    santaComponents,
    PropTypes,
    componentsCore,
    ReactDOM
) {
    'use strict';

    function getNonEmptyText(text) {
        text = text && text.trim();
        return text || '\u00A0';
    }


    /**
     * @class components.MenuButton
     * @extends {core.skinBasedComp}
     */
    const menuButton = {
        displayName: 'MenuButton',
        propTypes: {
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,

            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            style: santaComponents.santaTypesDefinitions.Component.style,

            refInParent: PropTypes.string.isRequired,
            isContainer: PropTypes.bool,
            isSelected: PropTypes.bool,
            display: PropTypes.string,
            positionInList: PropTypes.string,
            dir: PropTypes.string,
            mouseEnterHandler: PropTypes.func.isRequired,
            mouseLeaveHandler: PropTypes.func.isRequired,
            onMouseClick: PropTypes.func.isRequired,
            isDropDownButton: PropTypes.bool,
            parentId: PropTypes.string
        },
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.skinInfo],

        getInitialState() {
            return {
                $container: this.props.isContainer ? 'drop' : 'menu',
                $selected: this.props.isSelected ? 'selected' : '',
                $state: 'idle',
                $type: this.props.compData.link ? 'link' : 'header',
                $mobile: this.props.isMobileDevice || this.props.isMobileView || this.props.isTabletDevice() ? 'mobile' : 'notMobile'
            };
        },

        componentWillReceiveProps(nextProps) {
            this.setState({
                '$selected': nextProps.isSelected ? 'selected' : ''
            });
        },

        getSkinProperties() {
            const compData = this.props.compData;
            const alignText = this.props.compProp.alignText;
            const linkElementSkinProps = {
                parentConst: santaComponents.utils.createReactElement.bind(null, 'a'),
                onClick: this.onMouseClick,
                'data-listposition': this.props.positionInList,
                tabIndex: compData.link ? undefined : '0'
            };
            if (!this.props.isDropDownButton) {
                _.assign(linkElementSkinProps, {
                    'aria-haspopup': this.props['aria-haspopup']
                });
            }
            if (this.state.$mobile !== 'mobile') {
                _.assign(linkElementSkinProps, {
                    onMouseEnter: this.openSubMenu,
                    onMouseLeave: this.closeSubMenu,
                    onFocus: this.openSubMenu,
                    onBlur: this.closeSubMenu,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceButton
                });
            }
            if (this.props.refInParent === '__more__') {
                _.assign(linkElementSkinProps, {
                    tabIndex: 0
                });
            }
            if (compData.link) {
                _.merge(linkElementSkinProps, compData.link.render);
            }
            return {
                '': {
                    tagName: this.props.tagName || 'div',
                    'data-direction': this.props.direction,
                    'data-listposition': this.props.positionInList,
                    'data-parent-id': this.props.parentId,
                    'data-data-id': this.props.dataId
                },
                linkElement: linkElementSkinProps,
                bg: {
                    style: {textAlign: alignText}
                },
                label: {
                    children: getNonEmptyText(compData.label),
                    dir: this.props.dir,
                    style: {textAlign: alignText}
                }
            };
        },
        openSubMenu() {
            if (this.props.compData.link || this.props.refInParent === '__more__') {
                this.setState({$state: 'over'});
            }
            const menuBtnDomNode = ReactDOM.findDOMNode(this);
            this.props.mouseEnterHandler(this.props.refInParent, menuBtnDomNode.getAttribute('data-listposition'));
        },
        closeSubMenu() {
            if (this.props.isDropDownButton) {
                this.setIdleState();
            }
            this.props.mouseLeaveHandler(this.props.refInParent);
        },
        onMouseClick(event) {
            if (this.state.$mobile !== 'notMobile') {
                this.props.onMouseClick(event, this.props.refInParent, this.props.isDropDownButton);
            }
        },

        setIdleState() {
            this.setState({$state: 'idle'});
        }
    };

    componentsCore.compRegistrar.register('core.components.MenuButton', menuButton);

    return menuButton;
});
