define([
    'lodash',
    'coreUtils'
], function (
    _,
    coreUtils) {
    'use strict';

    function handlePreCondition(behavior, siteAPI) {
        coreUtils.animationsService.hideElementsByAnimationType(siteAPI, [behavior]);
    }

    function cancelPreCondition(behavior, siteAPI) {
        coreUtils.animationsService.revertHideElementsByAnimations(siteAPI, [behavior]);
    }

    function isMobileAnimationViewModeFixed(siteData) {
        const masterPageDataItem = siteData.getDataByQuery('masterPage');
        return _.has(masterPageDataItem, ['mobileSettings', 'animationsEnabled']);
    }

    function handle(behaviors, siteAPI, event) {
        const siteData = siteAPI.getSiteData();
        const runtimeDal = siteAPI.getRuntimeDal();
        const animationGroup = event.group || event.action;
        const clear = _.get(event, 'clear', true);
        const animations = _(behaviors)
            .filter(function (behavior) {
                const compProps = runtimeDal.getCompProps(behavior.targetId) || {};
                const isCollapsed = compProps.isCollapsed;
                const isAnimationEnabled = isEnabled(behavior, siteAPI);
                const isAnimationPlayable = coreUtils.animationsService.isAnimationPlayable(siteAPI, behavior);
                return !isCollapsed && isAnimationEnabled && isAnimationPlayable;
            })
            .map(function behaviorToAnimation(behavior) {
                return _.omit(_.defaultsDeep({}, behavior, _.find(siteData.animations.viewerDefaults, {name: behavior.name})), 'action');
            })
            .value();
        if (!_.isEmpty(animations)) {
            coreUtils.animationsService.playAnimations(siteAPI, animationGroup, animations, clear, event.callback || _.noop);
        } else {
            _.defer(function () {
                _.get(event, 'callback', _.noop)();
            });
        }
    }

    function isBehaviorAppliedInMobile(behavior) {
        return behavior && (!behavior.viewMode || behavior.viewMode === 'MOBILE');
    }

    function isEnabled(behavior, siteAPI) {
        const siteData = siteAPI.getSiteData();
        const isDesktop = !siteData.isMobileView();

        if (isDesktop) {
            return behavior.viewMode !== 'MOBILE';
        } else if (isMobileAnimationViewModeFixed(siteData)) {
            return isBehaviorAppliedInMobile(behavior);
        }

        return false;
    }

    return {
        handle,
        handlePreCondition,
        cancelPreCondition,
        isEnabled
    };
});
